import DashboardLayout from "@/pages/layouts/DashboardLayout";

let categoryRoutes = {
  path: '/categories',
  component: DashboardLayout,
  redirect: '/categories/list',
  children: [
    {
      path: 'list',
      name: 'Category List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/categories/CategoryList'),
      meta: {
        permissionsCodes: ['categories/index', 'restricted_own/categories/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Category',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/categories/CategoryForm'),
      meta: {
        permissionsCodes: ['categories/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Category',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/categories/CategoryForm'),
      meta: {
        permissionsCodes: ['categories/update', 'restricted_own/categories/update'],
      }
    }
  ]
}

export default categoryRoutes;
