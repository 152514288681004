const sharedMethods = {
    getEditorConfig: function () {
      return {
        height: 400,
        paste_data_images: true,
        plugins: [
          "advlist autolink lists link image charmap print preview hr anchor pagebreak",
          "searchreplace wordcount visualblocks visualchars code fullscreen",
          "insertdatetime media nonbreaking save table contextmenu directionality",
          "emoticons template paste textcolor colorpicker textpattern"
        ],
        toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | customInsertButton",
        toolbar2: "print preview media | forecolor backcolor emoticons",
        image_advtab: true,
        branding: false,
        content_style: ".highlight{background-color: lightblue;}",
        setup: function(editor) {
          editor.ui.registry.addButton("customInsertButton", {
            icon: "permanent-pen",
            text:"Highlight ",
            onAction: function (_) {
              var newContent ="<span class='highlight'>"+editor.selection.getContent()+"</span>";
              editor.selection.setContent(newContent);
            }
          });
        }
      }
    },
    handleSubmittingErrors(errorMessage, errorClass = '.invalid-feedback'){
      const errorFirst = this.$el.querySelector(errorClass)
      const errorPosition = errorFirst.getBoundingClientRect();
      this.showSubmitErrors(errorMessage, errorPosition)
    },
    showSubmitErrors(message, position = {x: 0, y: 100}, type = 'danger'){
      this.$notify({
        message: message,
        timeout: 1000,
        type: type
      });
      window.scrollBy(position.x, position.y - 100);
    },
    generateSomeFields(text, replacement = '_') {
      let newUrl = '';
      if (text) {
        newUrl = text.replace(/\s+/g, "_").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, replacement);
        newUrl = newUrl.replace(/([-]+)/g, replacement);
      }
      return newUrl;
  },
  getImagesExtensions() {
    return ['png', 'jpeg', 'gif', 'jpg', 'svg'];
  },
  getVideoExtensions() {
    return ['webm', 'webm', 'mkv', 'flv', 'gif', 'mov', 'wmv', 'mp4', '3gp', 'vob', 'ogg', 'gifv']
  },

};

export default sharedMethods;

