<template>
  <div>
    <FileUpload :name="getName()" :url="getUploadUrl()"
                @upload="afterUpload"
                @before-send="beforeSend"
                :fileLimit="filelimits"
                :multiple="isMultiple()"
                :accept='fileType' :maxFileSize="maxFileSize">
      <template #empty>
        <p>Drag and drop files to here to upload.</p>
      </template>
    </FileUpload>
    <div v-if="!isMultiple() && value" class="el-upload-list--picture-card">
      <div class="el-upload-list__item" :style="{width:previewWidth,height:previewHeight}">
        <img v-if="fileTypeName == 'image'" alt="" :src="getUrl()+value" class="el-upload-list__item-thumbnail">
        <video v-if="fileTypeName == 'video'" controls>
          <source :src="getUrl()+value">
          Your browser does not support the video tag.
        </video>
        <span class="el-upload-list__item-actions">
        <span class="el-upload-list__item-preview" @click="handleFileCardPreview"><i
          class="el-icon-zoom-in"></i></span>
        <span class="el-upload-list__item-delete" @click="handleRemove"><i class="el-icon-delete"></i></span></span>
      </div>
    </div>

    <div v-if="isMultiple() && value.length" class="el-upload-list--picture-card">
      <draggable v-model="value" group="gallary" @start="drag=true" @end="moveEventfunction" class="d-flex flex-wrap">
        <div v-for="(file, x) in value" :key="'file_'+x">
          <div class="el-upload-list__item" :style="{width:previewWidth,height:previewHeight}">
            <img v-if="fileTypeName == 'image'" alt="" :src="getUrl()+file.image" class="el-upload-list__item-thumbnail">
            <video v-if="fileTypeName == 'video'" controls>
              <source :src="getUrl()+file.image">
              Your browser does not support the video tag.
            </video>
            <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="handleMultipleFileCardPreview(file.image)"><i
            class="el-icon-zoom-in"></i></span>
          <span class="el-upload-list__item-delete" @click="handleRemove(x)"><i
            class="el-icon-delete"></i></span></span>
          </div>
        </div>
      </draggable>

    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img v-if="fileTypeName == 'image'" style="max-width:100%" :src="dialogFileUrl" alt="">
      <video v-if="fileTypeName == 'video'" id="video-preview" controls width="650" height="300">
        <source :src="dialogFileUrl">
        Your browser does not support the video tag.
      </video>
    </el-dialog>
  </div>


</template>

<script>

import {Button, Dialog, Upload} from 'element-ui';
import FileUpload from 'primevue/fileupload';
import draggable from 'vuedraggable';


export default {

  name: "PrimeUploader",
  props: {
    elementNum: {
      default: 1
    },
    maxFileSize: {
      default: 1000000
    },
    buttonTitle: {
      default: "Click To Upload"
    },
    value: {},
    previewWidth: {
      type: String,
      default: "240px"
    },
    previewHeight: {
      type: String,
      default: "auto"
    },
    fileType: {
      type: String,
      default: 'image/*'
    },
    fileTypeName: {
      type: String,
      default: 'image'
    }
  },

  components: {
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    FileUpload,
    draggable,
  },

  data() {
    return {
      images: [],
      filelimits: 1,
      dialogFileUrl: '',
      disabled: false,
      dialogVisible: false
    };
  },
  mounted() {
    this.filelimits = this.elementNum;
  },
  methods: {
    moveEventfunction() {
      this.value.map((element, index) => {
        element.sort_order = index
      })
    },
    beforeSend: function (event) {
      this.filelimits++;
      event.xhr.setRequestHeader('Authorization', "Bearer " + this.$store.getters["auth/token"]);
    },
    afterUpload: function (event) {
      let resp = JSON.parse(event.xhr.response);
      if (this.isMultiple()) {
        this.images = this.value;
        this.images = this.images.concat(resp.data.url);
        this.$emit("input", this.images);
      } else {
        this.$emit("input", resp.data.url);
      }
    },
    handleFileCardPreview() {
      this.dialogFileUrl = process.env.VUE_APP_SERVER_IMAGE_URL + this.value;
      this.dialogVisible = true;
    },
    handleMultipleFileCardPreview(file) {
      this.dialogFileUrl = process.env.VUE_APP_SERVER_IMAGE_URL + file;
      this.dialogVisible = true;
    },
    handleRemove(x = 1000) {
      if (!this.isMultiple()) {
        this.$emit('input', "");
      } else {
        this.value.splice(x, 1);
        this.$emit("input", this.value);
      }
    },
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },

    getUploadUrl() {
      if (this.elementNum === 1) {
        return process.env.VUE_APP_SERVER_IMAGE_URL + 'api/files/upload-' + this.fileTypeName;
      } else {
        return process.env.VUE_APP_SERVER_IMAGE_URL + 'api/files/upload-multiple-' + this.fileTypeName;
      }
    },
    isMultiple() {
      return (this.elementNum !== 1);
    },
    getName() {
      if (this.fileTypeName == 'video'){
        return (this.isMultiple()) ? 'video[]' : 'video';
      }else{
        return (this.isMultiple()) ? 'photo[]' : 'photo';
      }

    }
  },
}
</script>
<style scoped>
.el-upload-list__item {
  margin-top: 10px;
}

</style>
